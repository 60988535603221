.hide-overlay {
  opacity: 0;
  visibility: hidden;
}

.about-overlay {
  transition: opacity 0.5s ease, visibility 0.5s ease;
  opacity: 1;
}

.about-container {
  display: flex;
  justify-content: center;
  align-items: start;
  width: 100%;
  height: calc(100% - 144px);
  overflow-y: auto; /* Enable vertical scrolling */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.about-container::-webkit-scrollbar {
  width: 0; /* Safari and Chrome */
}

.about-content {
  font-size: larger;
  max-width: 60%;
  color: rgb(255, 255, 255);
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}

.about-content button {
  font-family: "Tw Cen MT Std", sans-serif;
  font-size: 1rem;
  background-color: transparent;
  color: #ffffff;
  border: none;
  border-radius: 0;
  cursor: pointer;
}

.about-overlay {
  background-color: rgba(0, 0, 0, 0.95);
  height: 100vh;
}

.about-overlay .header {
  height: unset;
  width: unset;
  background-image: none;
}

.fa-icon {
  padding-right: 0.5rem;
}

.back-button {
  padding-top: 2rem;
}

.about-logo {
  position: relative;
  width: 15rem;
  top: 1rem;
  left: 2.5rem;
  padding: 3% 0;
  pointer-events: auto;
}

.mobile-buttons,
.mobile-footer {
  display: none;
}

@media (max-width: 768px) {
  p {
    text-align: justify;
  }

  .desktop-buttons,
  .about-content h1 {
    display: none;
  }

  .mobile-buttons {
    padding-top: 1rem;
    display: inline-block;
  }

  .header {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
  }

  .about-logo {
    top: unset;
    left: unset;
    width: 12rem;
    padding: 3% 0;
    pointer-events: auto;
  }

  .about-overlay {
    background-color: rgba(0, 0, 0, 0.95);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .about-container {
    margin-top: 6rem;
    display: flex;
    justify-content: center;
    width: 100%;
    height: calc(100% - 180px);
    overflow: scroll;
  }

  .about-content {
    font-size: 85%;
    max-width: 75%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mobile-footer {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    z-index: 10;
  }

  .mobile-footer button {
    background: none;
    border: none;
    color: #ffffff;
    font-size: 1.5rem;
    margin: 0 1rem;
  }

  .video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    position: relative;
    width: 100%;
  }

  .video-container video {
    max-width: 100%;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #000;
  }

  .about-content::-webkit-scrollbar {
    display: none;
  }

  .about-content {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
