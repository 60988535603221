.header {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    148deg,
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgb(0, 0, 0, 0.55)
  );
  padding: 10px 0;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40%;
  /* background-color: aqua; */
  background-image: linear-gradient(
    188deg,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.9)
  );
  color: #fff;
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.homepage-logo {
  position: relative;
  width: 15rem;
  top: 1rem;
  left: 2.5rem;
  pointer-events: auto;
}

.video-background {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  pointer-events: none;
}
.homepage-overlay {
  font-family: "Tw Cen MT Std", sans-serif;
  position: fixed;
  width: 100%;
  height: 100%;
}

.overlay-links {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 15px;
  font-size: 1.5rem;
}

.overlay-links a {
  color: #fff;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
}

.overlay-links button {
  font-family: "Tw Cen MT Std", sans-serif;
  font-size: 1.5rem;
  background-color: transparent;
  color: #ffffff;
  border: none;
  border-radius: 0;
  cursor: pointer;
}

.overlay-text {
  text-align: left;
  width: 30rem;
  position: fixed;
  bottom: 20px;
  left: 20px;
  color: #fff;
  /* background-color: rgba(0, 0, 0, 0.5); */
  padding: 1.5rem;
  border-radius: 5px;
  font-size: 3rem;
}

.video-control {
  display: flex;
  gap: 10px;
}

.video-control button {
  background-color: transparent;
  color: #ffffff;
  border: none;
  border-radius: 0;
  cursor: pointer;
  position: relative;
  font-size: 0.4em;
  pointer-events: auto;
  padding-bottom: 1rem;
}

.homepage-overlay {
  transition: opacity 0.5s ease, visibility 0.5s ease;
}

@media (max-width: 768px) {
  .footer,
  .homepage-overlay,
  .video-control {
    display: none;
  }
  .video-background {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
    z-index: -1;
    pointer-events: none;
  }
}
