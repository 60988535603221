@import "/src/data/styles.css";
@import url("https://fonts.cdnfonts.com/css/tw-cen-mt-std");

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: var(--background-color);
}

::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(0, 0, 0);
}

.disabled {
  color: var(--outline-color);
  pointer-events: none;
}

.disabled:hover {
  cursor: not-allowed;
}

.page-content {
  background-color: var(--content-background-color);
  position: relative;
  min-height: 100vh;
  margin-top: -120px;
  padding-top: 120px;
}

.page-content:before,
.page-content:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: calc((100% - 1200px) / 2);
  background-color: var(--background-color);
}

.page-content:before {
  left: 0;
  z-index: 1;
}

.page-content:after {
  right: 0;
  z-index: 1;
}

.content-wrapper {
  max-width: 1000px;
  margin: 0 auto;
}

.title {
  color: var(--primary-color);
  font-family: var(--secondary-font);
  font-size: 45px;
  font-weight: 700;
  width: 70%;
}

.subtitle {
  padding-top: 10px;
  color: var(--secondary-color);
  font-size: 16px;
  line-height: 28px;
  width: 70%;
  margin-top: 25px;
}

.subtitle .page-footer {
  position: block;
}

.page-footer::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  border-top: 2px solid var(--tertiary-color);
}

@media (max-width: 1270px) {
  .page-content:before,
  .page-content:after {
    width: calc((100% - 90%) / 2);
  }

  .content-wrapper {
    max-width: 90%;
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media (max-width: 1024px) {
  .page-content:before,
  .page-content:after {
    width: calc((100% - 950px) / 2);
  }

  .content-wrapper {
    max-width: 980px;
  }
}

@media (max-width: 800px) {
  .content-wrapper {
    max-width: 100%;
    margin: 0 auto;
    padding: 0px 10px;
  }

  body {
    background-color: var(--content-background-color);
  }

  .page-content:before,
  .page-content:after {
    display: none;
  }
}
